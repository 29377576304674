<template>
  <div class="home">
    <!--    <h3 class="common-title">文档列表</h3>-->
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="documentFilter.keyword" placeholder="请输入文档关键词" clearable
                  @keyup.enter="getDocumentList"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getDocumentList">
          检索
        </el-button>
      </div>
      <div class="fr">
        <el-button type="primary" @click="openDocumentDialog">追加</el-button>
      </div>
      <div class="fr mr5">
        <el-button type="primary" @click="openDocumentConfigDialog">文档设置</el-button>
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="documentList.length === 0"></Empty>
      <el-table
        :data="documentList"
        class="home-list"
        style="width: 100%"
        @cell-click="cellHandleClick"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="60"
        >
        </el-table-column>
        <el-table-column label="内容">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.content">{{ scope.row.content }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template #default="scope">
            <el-button
              class="mt5"
              type="primary"
              link
              @click.stop="openDocumentDialog(scope.row)">
              更新
            </el-button>

            <el-button
              class="mt5"
              type="danger"
              link
              @click.stop="deleteDocument(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="documentConfigDialog" title="文档设置" destroy-on-close draggable align-center>
      <div class="home-right" v-loading="loading">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          label-width="120px"
          class="home-form"
          height="calc(100vh - 300px)"
        >
          <el-form-item
            label="分片大小"
            prop="chunk_size"
          >
            <el-input-number
              v-model="form.chunk_size"
              :min="600"
              :max="3000"
              :precision="0"
              placeholder="800">
            </el-input-number>
          </el-form-item>
          <el-form-item label="停用词" prop="es_stop_words">
            <el-input
              type="textarea"
              rows="10"
              v-model="form.es_stop_words"
              :placeholder=configInfo.es_stop_words
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="documentConfigDialog = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="documentDialog" title="文档上传" destroy-on-close draggable align-center>
      <div class="home-right" v-loading="loading">
        <el-form
          ref="ruleFormRef"
          :model="documentForm"
          :rules="documentRules"
          label-width="120px"
          class="home-form"
        >
          <el-form-item v-if="documentForm.document_id">
            <div style="margin-top: 20px">
              <el-radio-group v-model="documentLanguage">
                <el-radio-button label="zh-CN" key="zh-CN">中文</el-radio-button>
                <el-radio-button v-for="config in merchantServerLanguages" :label="config.lang" :key="config.lang">
                  {{ config.name }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="文件"
            prop="file"
            required
          >
            <div class="w_10 dflex">
              <el-upload
                ref="upload"
                :limit="1"
                :on-exceed="handleExceed"
                :on-change="handleChange"
                :auto-upload="false"
                accept=".txt"
              >
                <template #trigger>
                  <div>
                    <el-button type="primary">选择文件</el-button>
                  </div>
                </template>
              </el-upload>
            </div>
            <input type="hidden" :value="documentForm.file"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="documentDialog = false">取消</el-button>
          <el-button type="primary" @click="documentUpload">确定</el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog v-model="documentPreviewDialog" title="分片预览" width="80%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right" v-loading="loading">
        <Empty v-if="documentPreviewList.length === 0"></Empty>
        <el-table
          :data="documentPreviewList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 300px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="60">
          </el-table-column>
          <el-table-column
            prop="text"
            label="文本">
            <template #default="scope">
              <p class="preview-cell">{{ scope.row.text }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt5 pb5">
        </div>
      </template>
    </el-dialog>


    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant form
    const documentList = ref([])
    const merchant = computed(() => {
      return store.getters.merchant
    })

    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const merchantRuleFormRef = ref(null)

    const merchantRules = ref({})

    const documentFilter = reactive({
      keyword: '',
    })
    const getDocumentList = () => {
      loading.value = true
      authApi.getMerchantDocument(merchant.value.id, documentFilter).then(res => {
        console.log(res)
        documentList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }

    // 文档配置 form
    const formRaw = reactive({
      merchant_id: null,
      chunk_size: null,
      es_stop_words: null,
    })

    const form = reactive(JSON.parse(JSON.stringify(formRaw)))
    const loading = ref(false)
    const rulesRaw = reactive({
      merchant_id: [{ required: true, message: '请选择设施', trigger: 'blur' },]
    })
    const rules = ref({})
    const ruleFormRef = ref(null)

    const documentConfigDialog = ref(false)
    const openDocumentConfigDialog = (item) => {
      const newItem = merchant.value
      Object.keys(formRaw).forEach(key => {
        form[key] = newItem ? newItem[key] : formRaw[key]
      })
      form.merchant_id = newItem.id
      form.chunk_size = newItem.config.chunk_size
      form.es_stop_words = newItem.config.es_stop_words
      documentConfigDialog.value = true
    }

    const submit = () => {
      ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          console.log(form)
          authApi.upload(form.merchant_id, form).then(res => {
            console.log(res)
            ElMessage({
              message: '保存成功',
              type: 'success',
            })
            authApi.getMerchantDetail(form.merchant_id).then(res => {
              store.commit('setMerchant', res.data)
              documentConfigDialog.value = false
            }).finally(() => {
              loading.value = false
            })
          })
        }
      })
    }

    // 文档 form
    const documentForm = reactive({
      file: null,
      document_id: null,
    })

    const documentRules = reactive({
      file: [{ required: true, message: '请选择文件', trigger: 'blur' },]
    })

    const documentLanguage = ref('zh-CN')

    const upload = ref(null)
    const handleExceed = (files) => {
      upload.value.clearFiles()
      const file = files[0]
      file.uid = genFileId()
      upload.value.handleStart(file)
    }
    const handleChange = (file, fileList) => {
      console.log(file)
      documentForm.file = file.raw
    }

    const documentUpload = () => {
      ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          console.log(documentForm)
          const formData = new FormData()
          formData.append('merchant_id', merchant.value.id)
          formData.append('file', documentForm.file)
          console.log(formData)
          if (documentForm.document_id) {
            if (documentLanguage.value !== 'zh-CN') {
              formData.append('lang', documentLanguage.value)
            }
            formData.append('document_id', documentForm.document_id)
            authApi.documentUpdate(merchant.value.id, documentForm.document_id, formData).then(res => {
              console.log(res)
              ElMessage({
                message: '更新成功',
                type: 'success',
              })
              documentDialog.value = false
              getDocumentList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.documentUpload(merchant.value.id, formData).then(res => {
              console.log(res)
              ElMessage({
                message: '上传成功',
                type: 'success',
              })
              documentDialog.value = false
              getDocumentList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    const downloadDocument = () => {
      authApi.downloadDocument(merchant.value.id, merchant.value.document.id).then(res => {
        download(res)
      })
    }

    const documentDialog = ref(false)
    const openDocumentDialog = (item) => {
      documentForm.document_id = item ? item.id : null
      documentDialog.value = true
    }


    const downloadRef = ref(null)
    const download = (res) => {
      let fileName = ''
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        // 正規表現
        const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          // matches[1]でとれる⇒ filename*=utf-8''201911%E3%83%87%E3%83%BC%E3%82%BF.csv;
          // 不要文字列を消して、デコードしてサーバからのファイル名を取得
          fileName = decodeURI(
            matches[1].replace(/['"]/g, '').replace('utf-8', '')
          )
        }
      }
      const fileURL = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/octet-stream' })
      )
      console.log(fileURL)
      downloadRef.value.href = fileURL
      downloadRef.value.setAttribute('download', fileName)
      downloadRef.value.click()
      window.URL.revokeObjectURL(fileURL)
    }

    const configInfo = computed(() => {
      return store.getters.config
    })

    const cellHandleClick = (row, column, event, cell) => {
      console.log(row, column, event, cell)
      router.replace({
        name: 'MerchantFileDocumentDetail',
        params: {
          id: row.id,
        }
      })
    }
    // dataset preview
    const documentPreviewDialog = ref(false)
    const documentPreviewList = ref([])
    const documentPreview = () => {
      loading.value = true
      const data = JSON.parse(JSON.stringify(form))

      if (data.content === null || data.content === '' || data.content === undefined) {
        ElMessage({
          message: '请输入文档',
          type: 'error',
        })
        loading.value = false
        return
      }
      authApi.uploadPreview(data).then(res => {
        documentPreviewList.value = res.data
        documentPreviewDialog.value = true
      }).finally(() => {
        loading.value = false
      })
    }

    const deleteDocument = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除文档',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteDocument(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getDocumentList()
        }).finally(() => {
          loading.value = false
        })
      })
    }

    const addDocument = () => {
      documentDialog.value = true
    }

    onMounted(() => {
      getDocumentList()
    })
    return {
      form,
      loading,
      rules,
      ruleFormRef,
      upload,
      handleExceed,
      submit,
      handleChange,
      documentList,
      merchant,
      merchantRuleFormRef,
      merchantRules,
      downloadDocument,
      deleteDocument,
      documentDialog,
      openDocumentDialog,
      downloadRef,
      documentFilter,
      getDocumentList,
      configInfo,
      documentForm,
      documentRules,
      documentLanguage,
      documentUpload,

      documentConfigDialog,
      openDocumentConfigDialog,
      merchantServerLanguages,

      documentPreviewDialog,
      documentPreviewList,
      documentPreview,
      cellHandleClick,
      addDocument
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.document-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
